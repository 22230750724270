import React from 'react';
import { LayoutSingleColumn } from '../../components';
import FooterSection from '../LandingPage/FooterSection/Footer';
import TopbarContainer from '../TopbarContainer/TopbarContainer';
import css from './FAQsPage.module.css';
const FAQsPage = () => {
  return (
    <LayoutSingleColumn topbar={<TopbarContainer currentPage="FAQs" />} footer={<FooterSection />}>
      <div className={css.faqsContainer}>
        <h1>FAQs</h1>
        <h5>What is the pick up policy?</h5>
        <p>
          Once you place an order through Locago, you have 5 days to pick up your items from the
          respective store. Please bring your order confirmation and a valid ID for verification at
          pickup.
        </p>
        <h5>What if I cannot pick up my order within the 5 day window?</h5>
        <p>
          If you're unable to pick up your order within 5 days, please contact the store directly to
          discuss options.
        </p>
        <h5>Can someone else pick up my order on my behalf?</h5>
        <p>
          Yes, someone else may pick up your order with your order confirmation and a valid ID. We
          also recommend you contact the store directly to let them know.
        </p>
        <h5>What is the return policy?</h5>
        <p>
          You may return an item to the store up to 14 days after your pickup date. All returned
          items must be in the same condition as received, including any packaging, tags, or
          accessories. The store reserves the right to reject returns that do not meet these
          conditions.
        </p>
        <h5>How do I initiate a return?</h5>
        <p>
          Navigate to the Your Orders page and select the specific order in mind. If it is within
          the eligible 14 day return window, please click the button labeled “Start a Return” and
          follow the instructions.
        </p>
        <h5>When will I get my refund?</h5>
        <p>
          Once the store has received your return, please allow up to 14 business days from the
          return date for the refund to appear on your bank statement. All refunds will be issued to
          the original form of payment.
        </p>
        <h5>Can I combine returns from different orders?</h5>
        <p>
          If you have multiple returns from the same store, you can drop off items from different
          orders at the same time. However, each order return must be initiated separately within
          your account so that we can process them correctly.
        </p>
        <h5>How can I cancel my return request?</h5>
        <p>
          If you change your mind after starting a return, you can simply keep your items and allow
          your return window to expire. This occurs 14 days after pick up.
        </p>
        <h5>Can I request a store credit instead of a refund?</h5>
        <p>At this time, Locago does not offer store credits.</p>
        <h5>Can I request an exchange instead of a return?</h5>
        <p>
          At the time, Locago does not support exchanges. If you’d like a different item from the
          store, please process a return for the original purchase and place a new order for the
          desired item.
        </p>
        <h5>What if I submit my return request late?</h5>
        <p>
          After 14 days from the pickup date, items are ineligible for return or exchange. If you
          have any concerns, please speak with the store directly.
        </p>
        <p>If you have any additional questions, please contact info@locago.shop</p>
      </div>
    </LayoutSingleColumn>
  );
};

export default FAQsPage;
